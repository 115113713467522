<template>
  <v-container fluid class="pa-0">
    <p class="adv-text-subtitle mb-0">{{ $t('product.coupon_code') }}</p>
    <template v-if="!discountCoupon">
      <v-row no-gutters v-if="!showInputFieldOption">
        <v-col cols="12">
          <v-checkbox
            v-model="showInputFieldOption"
            class="checkboxColor"
            on-icon="mdi-plus"
            off-icon="mdi-plus"
            hide-details
            :label="$t('product.add_discount_code')"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="showInputFieldOption"
        dense
        justify="center"
        align="start"
        class="flex-nowrap"
      >
        <v-col cols="8">
          <FormTextField
            :value="promoCodeText"
            :placeholder="$t('product.promo_or_coupon_code')"
            @change="onPromoCodeChange"
            @onEnter="getPromoStatus()"
            class="contact"
            :class="{ 'contact-error': errorCoupon }"
            hide-details
            dense
            :height="44"
          />
        </v-col>
        <v-col cols="4">
          <v-btn
            color="primary"
            elevation="2"
            class="px-8 ml-1 size16-weight400"
            @click="getPromoStatus()"
            :loading="isLoading"
            :height="44"
          >
            {{ $t('apply') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-sheet outlined class="pa-3 rounded-lg selected-promo-code pr-10">
        <v-row no-gutters justify="space-between" align="center">
          <div class="d-flex align-center">
            <div class="mr-3 mt-2">
              <simple-svg
                width="24"
                height="24"
                :src="require(`@/assets/check-circle.svg`)"
              />
            </div>
            <div class="size16-weight600 d-flex flex-column">
              <span class="d-flex align-center size16-weight600">
                {{ discountCoupon.name }}
              </span>
              <span class="size12-weight400">{{ discountCoupon.label }}</span>
            </div>
          </div>
          <div class="size16-weight400">
            {{ discountCoupon.termsAndConditions }}
          </div>
          <v-btn
            icon
            @click="removeDiscount"
            class="selected-promo-code__remove"
          >
            <simple-svg
              width="20"
              height="20"
              :src="require(`@/assets/close-filled.svg`)"
            />
          </v-btn>
        </v-row>
      </v-sheet>
    </template>
    <v-row no-gutters class="mt-1">
      <v-col cols="12">
        <slot name="footer">
          <template v-if="applyInfo">
            <div
              class="size14-weight400"
              :class="errorCoupon ? 'error-message' : 'success-message'"
            >
              {{ applyInfo }}
            </div>
          </template>
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormTextField from '@/core/components/FormTextField';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import { RepoFactory } from '@/core/repositories';
import ProductOrderInformationMixins from '@/modules/product/booking/mixins/ProductOrderInformationMixins';
import ProductCheckoutMixins from '@/modules/product/mixins/ProductCheckoutMixins';

export default {
  name: 'PromoCodeForm',
  components: { FormTextField },
  mixins: [
    ProductCheckoutMixins,
    GoogleTagManagerMixins,
    ProductOrderInformationMixins,
  ],
  props: {
    orderId: {
      required: true,
      type: String,
    },
    discountCoupon: {
      type: Object,
    },
    type: {
      type: String,
      default: 'booking',
    },
    showInputInitial: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showInputFieldOption: false,
      promoCodeText: '',
      applyInfo: '',
      isLoading: false,
      errorCoupon: false,
    };
  },

  mounted() {
    this.showInputFieldOption = this.showInputInitial;
  },

  methods: {
    onPromoCodeChange(val) {
      this.promoCodeText = val.value;
    },
    async getPromoStatus() {
      this.applyInfo = '';
      this.isLoading = true;

      const orderRepo = RepoFactory.get('order');
      const orderId = this.orderId;
      const promoCode = this.promoCodeText;

      let analyticsData;
      if (this.type === 'booking') {
        analyticsData = {
          productId: this.productId,
          date: this.selectedAvailabilityDate,
          option: this.selectedOption,
          price: this.amount.value,
          passes: this.productPasses,
          type: 'booking',
        };
      }
      try {
        const { data } = await orderRepo.getPromo({ orderId, promoCode });
        this.isLoading = false;
        if (this.type === 'booking') {
          analyticsData.status = 'successful';
          this.pushDiscountCodeStatus(analyticsData, promoCode);
        }
        this.$emit('submit:discountCoupon', data);
        this.errorCoupon = false;
      } catch (err) {
        this.applyInfo = err.response.data.errorMessage;
        this.isLoading = false;
        this.errorCoupon = true;
        if (this.type === 'booking') {
          analyticsData.status = 'invalid';
          this.pushDiscountCodeStatus(analyticsData, promoCode);
        }
      }
    },

    removeDiscount() {
      this.promoCodeText = '';
      this.applyInfo = this.$t('product.coupon_code_removed');
      this.$emit('clear:discountCoupon');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .checkboxColor {
  margin: 0 !important;
  padding: 0;
  i {
    color: var(--v-primary-base);
  }
  label {
    color: var(--v-primary-base) !important;
  }
}

::v-deep .v-input--selection-controls {
  margin-top: 11px;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    margin-top: 2px;
  }

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: var(--v-grey8-base);
  }
}

.adv-text-subtitle {
  @include font-size(14, 150, 600);
}

.success-message {
  color: var(--v-success-base);
  padding-left: 22px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 6px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: 2px solid var(--v-success-base);
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg);
  }
}

.error-message {
  color: var(--v-error-base);
  padding-left: 20px;
  position: relative;

  &:before {
    content: url('~@/assets/exclamation-circle.svg');
    position: absolute;
    left: 0;
    top: 2px;
  }
}

.selected-promo-code {
  position: relative;
  margin-top: 6px;

  &__remove {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.contact {
  ::v-deep .v-text-field--outlined {
    fieldset {
      border: 1px solid var(--v-grey4-base);
      border-radius: 8px;
    }

    input::placeholder {
      color: var(--v-grey7-base);
    }
  }

  ::v-deep .v-input--is-focused {
    fieldset {
      transition-duration: 0s;
      border: 2px solid var(--v-primary-base);
    }
  }

  &-error {
    ::v-deep .v-text-field--outlined {
      fieldset {
        border: 1px solid var(--v-error-base);
      }

      input {
        color: var(--v-error-base);
      }
    }

    ::v-deep .v-input--is-focused {
      fieldset {
        transition-duration: 0s !important;
        border: 2px solid var(--v-error-base) !important;
      }
    }
  }
}
</style>
