export const CATEGORIES_LOADED = 'CATEGORIES_LOADED';
export const CATEGORIES_LOADING = 'CATEGORIES_LOADING';
export const CATALOG_AVAILABILITY_UPDATE = 'CATALOG_AVAILABILITY_UPDATE';
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR';
export const LISTINGS_LOADING = 'LISTINGS_LOADING';
export const LISTINGS_LOADED = 'LISTINGS_LOADED';
export const LISTINGS_ERROR = 'LISTINGS_ERROR';
export const LISTINGS_CLEAR = 'LISTINGS_CLEAR';
export const FLIGHT_LOADING = 'FLIGHT_LOADING';
export const FLIGHT_LOADED = 'FLIGHT_LOADED';
export const FLIGHT_ERROR = 'FLIGHT_ERROR';
export const CATEGORY_SELECTED = 'CATEGORY_SELECTED';
export const OFFERING_SELECTED = 'OFFERING_SELECTED';
export const CURRENT_ORDER_ITEM = 'CURRENT_ORDER_ITEM';
export const CLEAR_ORDER_ITEM = 'CLEAR_ORDER_ITEM';
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const UPDATE_ITEM_IN_CART = 'UPDATE_ITEM_IN_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const ORDER_CREATING = 'ORDER_CREATING';
export const ORDER_CREATION_ERROR = 'ORDER_CREATION_ERROR';
export const ORDER_CREATED = 'ORDER_CREATED';
export const ORDER_CHECKING_OUT = 'ORDER_CHECKING_OUT';
export const ORDER_CHECKED_OUT = 'ORDER_CHECKED_OUT';
export const ORDER_CHECKED_OUT_ASYNC = 'ORDER_CHECKED_OUT_ASYNC';
export const ORDER_CHECK_OUT_TIMEOUT_ID = 'ORDER_CHECK_OUT_TIMEOUT_ID';
export const ORDER_CHECK_OUT_ERROR = 'ORDER_CHECK_OUT_ERROR';
export const ORDER_CHECK_OUT_REQUIRES_CONFIRMATION =
  'ORDER_CHECK_OUT_REQUIRES_CONFIRMATION';
export const ORDER_UPDATE_DISCOUNT = 'ORDER_UPDATE_DISCOUNT';
export const ORDER_REMOVE_DISCOUNT = 'ORDER_REMOVE_DISCOUNT';
export const UPDATE_CUSTOMER_INFO = 'UPDATE_CUSTOMER_INFO';
export const RESET_RESTRICTION_ACKNOWLEDGEMENT =
  'RESET_RESTRICTION_ACKNOWLEDGEMENT';
export const UPDATE_LAST_ORDER = 'UPDATE_LAST_ORDER';
export const RESET_ORDER_CREATION = 'RESET_ORDER_CREATION';
export const RESET_ORDER_CHECKOUT = 'RESET_ORDER_CHECKOUT';
export const CLEAR_ORDER_CREATION_ERRORS = 'CLEAR_ORDER_CREATION_ERRORS';
export const CLEAR_ORDER_CHECKOUT_ERRORS = 'CLEAR_ORDER_CHECKOUT_ERRORS';
export const CLEAR_STRIPE_ERROR = 'CLEAR_STRIPE_ERROR';
export const UPDATE_STRIPE_ERROR = 'UPDATE_STRIPE_ERROR';
export const SHOP_STATUS_MODIFIED = 'SHOP_STATUS_MODIFIED';
export const UPDATE_SPECIAL_INSTRUCTIONS = 'UPDATE_SPECIAL_INSTRUCTIONS';
export const CLEAR_SPECIAL_INSTRUCTIONS = 'CLEAR_SPECIAL_INSTRUCTIONS';
export const SHOP_STATUS_ERROR = 'SHOP_STATUS_ERROR';
export const UPDATE_IS_INFO_FORM_VALID = 'UPDATE_INFO_IS_FORM_VALID';
export const UPDATE_IS_PAYMENT_FORM_VALID = 'UPDATE_IS_PAYMENT_FORM_VALID';
export const RESET_INFO_FORM_VALIDATIONS = 'RESET_INFO_FORM_VALIDATIONS';
export const RESET_PAYMENT_FORM_VALIDATIONS = 'RESET_PAYMENT_FORM_VALIDATIONS';
export const CLEAR_SELECTED_OFFERING = 'CLEAR_SELECTED_OFFERING';
export const HIGHLIGHTED_OFFERING = 'HIGHLIGHTED_OFFERING';
export const CLEAR_HIGHLIGHTED_OFFERING = 'CLEAR_HIGHLIGHTED_OFFERING';
export const CLEAR_SELECTED_CATEGORY = 'CLEAR_SELECTED_CATEGORY';
export const SHOP_SEARCH_RESULT = 'SHOP_SEARCH_RESULT';
export const CLEAR_SHOP_SEARCH_RESULT = 'CLEAR_SHOP_SEARCH_RESULT';
export const FLIGHT_MISMATCH = 'FLIGHT_MISMATCH';
