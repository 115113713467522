import * as mutationTypes from './mutation-types';
import { v4 as uuidv4 } from 'uuid';
export const mutations = {
  [mutationTypes.CATEGORIES_LOADED](state, data) {
    state.categories = {
      ...state.categories,
      value: data,
      isLoading: false,
    };
  },

  [mutationTypes.CATALOG_AVAILABILITY_UPDATE](state, data) {
    state.isCatalogAvailable = data;
  },

  [mutationTypes.CATEGORIES_ERROR](state, data) {
    state.categories = {
      ...state.categories,
      isLoading: false,
      error: data,
    };
  },

  [mutationTypes.CATEGORIES_LOADING](state) {
    state.categories = {
      ...state.categories,
      isLoading: true,
    };
  },

  [mutationTypes.LISTINGS_LOADED](state, listings) {
    state.categoryListings = {
      ...state.categoryListings,
      value: listings,
      isLoading: false,
    };
  },

  [mutationTypes.LISTINGS_CLEAR](state) {
    state.categoryListings = {
      error: null,
      value: null,
      isLoading: false,
    };
  },

  [mutationTypes.LISTINGS_ERROR](state, data) {
    state.categoryListings = {
      ...state.categoryListings,
      isLoading: false,
      error: data,
    };
  },

  [mutationTypes.LISTINGS_LOADING](state) {
    state.categoryListings = {
      ...state.categoryListings,
      error: null,
      isLoading: true,
    };
  },

  [mutationTypes.SHOP_STATUS_MODIFIED](state, newStatus) {
    const oldValues = !!state.categories.value ? state.categories.value : [];
    state.categories = {
      ...state.categories,
      value: {
        ...oldValues,
        status: newStatus,
      },
      isLoading: false,
    };
  },

  [mutationTypes.FLIGHT_LOADED](state, data) {
    state.flight = {
      ...state.flight,
      value: data,
      isLoading: false,
    };
  },

  [mutationTypes.FLIGHT_ERROR](state, data) {
    state.flight = {
      ...state.flight,
      error: data,
      isLoading: false,
    };
  },

  [mutationTypes.FLIGHT_LOADING](state) {
    state.flight = {
      ...state.flight,
      isLoading: true,
    };
  },

  [mutationTypes.CATEGORY_SELECTED](state, name) {
    state.selectedCategory = {
      ...state.selectedCategory,
      name: name,
    };
  },

  [mutationTypes.OFFERING_SELECTED](state, offering) {
    state.selectedOffering = {
      ...state.selectedOffering,
      value: offering,
    };
  },

  [mutationTypes.CLEAR_SELECTED_OFFERING](state) {
    state.selectedOffering = null;
  },

  [mutationTypes.CURRENT_ORDER_ITEM](state, item) {
    state.currentOrderItem = {
      ...state.currentOrderItem,
      value: { ...item },
    };
  },

  [mutationTypes.CLEAR_ORDER_ITEM](state) {
    state.currentOrderItem = null;
  },

  [mutationTypes.FLIGHT_MISMATCH](state, payload = true) {
    state.flightMismatch = {
      ...state.flightMismatch,
      value: payload,
    };
  },

  [mutationTypes.ADD_ITEM_TO_CART](state, item) {
    item['orderId'] = uuidv4();
    state.cartItems = {
      ...state.cartItems,
      value: [...state.cartItems.value, item],
    };
  },

  [mutationTypes.UPDATE_ITEM_IN_CART](state, { item, orderId }) {
    const itemIndex = state.cartItems.value.findIndex(
      (cartItem) => cartItem.orderId === orderId
    );
    if (itemIndex > -1) {
      item.orderId = orderId;
      const cartItems = [...state.cartItems.value];
      cartItems[itemIndex] = item;
      state.cartItems = {
        ...state.cartItems,
        value: [...cartItems],
      };
    }
  },

  [mutationTypes.REMOVE_ITEM_FROM_CART](state, id) {
    const updatedCart = state.cartItems.value.filter((item) => {
      return item.orderId !== id;
    });
    state.cartItems = {
      ...state.cartItems,
      value: [...updatedCart],
    };
  },

  [mutationTypes.ORDER_CREATING](state) {
    state.orderCreation = { ...state.orderCreation, isCreating: true };
  },

  [mutationTypes.ORDER_CREATION_ERROR](
    state,
    { error, status } = { error: 'Error: Unknown error', status: 'Error' }
  ) {
    state.orderCreation = {
      ...state.orderCreation,
      error: error,
      isCreating: false,
      status,
    };
  },

  [mutationTypes.ORDER_CREATED](state, { response } = { response: {} }) {
    state.orderCreation = {
      ...state.orderCreation,
      response: { ...response },
      status: 'Created',
      isCreating: false,
    };
  },

  [mutationTypes.ORDER_UPDATE_DISCOUNT](
    state,
    { response } = { response: {} }
  ) {
    state.orderCreation = {
      ...state.orderCreation,
      response: { ...response },
    };
  },

  [mutationTypes.ORDER_REMOVE_DISCOUNT](state) {
    const creation = state.orderCreation.response;
    creation.amount.value = creation.amount.subTotal;
    creation.amount.discountTotal = null;
    creation.discount = null;
  },

  [mutationTypes.UPDATE_CUSTOMER_INFO](state, customer) {
    state.customerInfo = {
      ...state.customerInfo,
      ...customer,
    };
  },

  [mutationTypes.RESET_RESTRICTION_ACKNOWLEDGEMENT](state) {
    state.customerInfo = {
      ...state.customerInfo,
      restrictionAcknowledged: false,
    };
  },

  [mutationTypes.ORDER_CHECKING_OUT](state) {
    state.orderCheckout = { ...state.orderCheckout, isCheckingOut: true };
  },

  [mutationTypes.ORDER_CHECKED_OUT](state, { response } = { response: {} }) {
    state.orderCheckout = {
      ...state.orderCheckout,
      response: { ...response },
      clientConfirmationKey: null,
      isCheckingOut: false,
    };
  },

  [mutationTypes.ORDER_CHECKED_OUT_ASYNC](
    state,
    { response, isAsync = true, pollingRequiredInSec } = {
      response: {},
    }
  ) {
    state.orderCheckout = {
      ...state.orderCheckout,
      response: { ...response },
      async: isAsync,
      maxpollRetry: 10,
      timeoutId: null,
      pollingRequiredInSec,
    };
  },

  [mutationTypes.ORDER_CHECK_OUT_TIMEOUT_ID](state, { timeoutId }) {
    state.orderCheckout = {
      ...state.orderCheckout,
      maxpollRetry: state.orderCheckout.maxpollRetry - 1,
      timeoutId: timeoutId,
    };
  },

  [mutationTypes.ORDER_CHECK_OUT_ERROR](
    state,
    { error, status } = {
      error: 'Error: Unknown error in order check out',
      status: 'Error',
    }
  ) {
    state.orderCheckout = {
      ...state.orderCheckout,
      clientConfirmationKey: null,
      error: error,
      isCheckingOut: false,
      status,
    };
  },

  [mutationTypes.ORDER_CHECK_OUT_REQUIRES_CONFIRMATION](
    state,
    { clientConfirmationKey = '', id } = { clientConfirmationKey: '' }
  ) {
    state.orderCheckout = {
      ...state.orderCheckout,
      response: null,
      error: null,
      isCheckingOut: false,
      clientConfirmationKey: clientConfirmationKey,
    };
  },

  [mutationTypes.CLEAR_CART](state) {
    state.cartItems = {
      ...state.cartItems,
      value: [],
    };
  },

  [mutationTypes.UPDATE_LAST_ORDER](state, order) {
    state.lastOrder = {
      ...state.lastOrder,
      value: [...order],
    };
  },

  [mutationTypes.RESET_ORDER_CREATION](state) {
    state.orderCreation = {
      ...state.orderCreation,
      isCreating: false,
      response: null,
      error: null,
      status: 'Uninitialized',
    };
  },

  [mutationTypes.RESET_ORDER_CHECKOUT](state) {
    state.orderCheckout = {
      ...state.orderCheckout,
      clientConfirmationKey: null,
      async: null,
      error: null,
      response: null,
    };
  },

  [mutationTypes.CLEAR_STRIPE_ERROR](state) {
    state.checkoutForms = {
      ...state.checkoutForms,
      stripePaymentError: null,
    };
  },

  [mutationTypes.UPDATE_STRIPE_ERROR](state, error) {
    state.checkoutForms = {
      ...state.checkoutForms,
      stripePaymentError: error,
    };
  },

  [mutationTypes.CLEAR_ORDER_CREATION_ERRORS](state) {
    state.orderCreation = {
      ...state.orderCreation,
      error: null,
      status: 'Uninitialized',
    };
  },

  [mutationTypes.CLEAR_ORDER_CHECKOUT_ERRORS](state) {
    state.orderCheckout = {
      ...state.orderCheckout,
      error: null,
    };
  },

  [mutationTypes.UPDATE_SPECIAL_INSTRUCTIONS](state, instruction) {
    state.instruction = {
      ...state.instruction,
      value: instruction,
    };
  },

  [mutationTypes.CLEAR_SPECIAL_INSTRUCTIONS](state) {
    state.instruction = {
      ...state.instruction,
      value: null,
    };
  },

  [mutationTypes.SHOP_STATUS_ERROR](state, data) {
    state.shopStatusError = {
      ...state.shopStatusError,
      value: data,
    };
  },

  [mutationTypes.UPDATE_IS_INFO_FORM_VALID](state, data) {
    state.checkoutForms = {
      ...state.checkoutForms,
      isInfoFormValid: data,
    };
  },

  [mutationTypes.UPDATE_IS_PAYMENT_FORM_VALID](state, data) {
    state.checkoutForms = {
      ...state.checkoutForms,
      isPaymentFormValid: data,
    };
  },

  [mutationTypes.RESET_PAYMENT_FORM_VALIDATIONS](state) {
    state.checkoutForms = {
      ...state.checkoutForms,
      isPaymentFormValid: false,
    };
  },

  [mutationTypes.RESET_INFO_FORM_VALIDATIONS](state) {
    state.checkoutForms = {
      ...state.checkoutForms,
      isInfoFormValid: false,
    };
  },

  [mutationTypes.HIGHLIGHTED_OFFERING](state, id) {
    state.highlightedOffering = {
      ...state.highlightedOffering,
      value: id,
    };
  },

  [mutationTypes.CLEAR_HIGHLIGHTED_OFFERING](state) {
    state.highlightedOffering = null;
  },

  [mutationTypes.CLEAR_SELECTED_CATEGORY](state) {
    state.selectedCategory = null;
  },

  [mutationTypes.SHOP_SEARCH_RESULT](state, { item, text }) {
    state.searchResults = {
      ...state.searchResults,
      value: item,
      text: text,
    };
  },

  [mutationTypes.CLEAR_SHOP_SEARCH_RESULT](state) {
    state.searchResults = {
      value: [],
      text: null,
    };
  },
};
