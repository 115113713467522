<template>
  <div class="stripe-card">
    <div
      :id="buttonId"
      :style="{ display: displayButton ? null : 'none' }"
      class="pb-4"
    />
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';

export default {
  name: 'StripePaymentRequest',
  mixins: [ShopOrderMixins],
  props: {
    method: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      paymentRequest: null,
      canMakePayment: null,
      stripeNotLoaded: null,
      button: null,
      methods: {
        GooglePay: 'googlePay',
        ApplePay: 'applePay',
      },
    };
  },
  mounted() {
    if (!!this.$stripe && this.$stripe.lib) {
      this.stripeNotLoaded = false;
      this.setupStripe();
    } else {
      this.stripeNotLoaded = true;
      this.$store.dispatch('initStripe', {
        stripe_key: this.stripeKey,
        vm: this,
      });
      Sentry.captureMessage(
        'Attempting to initialize Stripe again since library is undefined'
      );
    }
  },
  computed: {
    buttonId() {
      return `payment-request-element-${this._uid}`;
    },
    stripeKey() {
      return this.$store.state.configs.stripe_key;
    },
    displayButton() {
      return (
        !!this.canMakePayment && this.canMakePayment[this.methods[this.method]]
      );
    },
  },
  methods: {
    async setupStripe() {
      const paymentRequest = this.$stripe.lib.paymentRequest({
        country: 'CA',
        currency: this.currency.toLowerCase(),
        total: {
          label: '',
          amount: this.orderTotalValue * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      this.button = this.$stripe.lib.elements().create('paymentRequestButton', {
        paymentRequest,
        style: {
          paymentRequestButton: {
            height: '50px',
          },
        },
      });

      this.canMakePayment = await paymentRequest.canMakePayment();
      this.$emit('input', this.canMakePayment);
      this.$emit('show-button', this.canMakePayment);
      if (this.canMakePayment) {
        this.button.mount(`#${this.buttonId}`);
        this.paymentRequest = paymentRequest;
        paymentRequest.on('paymentmethod', (event) => {
          this.$emit('change', event);
          setTimeout(() => event.complete('success'), 1000);
        });
      }
    },
    paramsWatcher() {
      if (this.paymentRequest) {
        this.paymentRequest.update({
          total: {
            label: this.label,
            amount: this.price * 100,
          },
        });
      }
    },
  },
  watch: {
    ['$stripe.lib']: {
      immediate: true,
      deep: true,
      handler(val, oldval) {
        if (!!val && !!this.stripeNotLoaded) {
          this.setupStripe();
        }
      },
    },
    price: {
      immediate: true,
      handler() {
        this.paramsWatcher();
      },
    },
    label: {
      immediate: true,
      handler() {
        this.paramsWatcher();
      },
    },
    paymentRequest: {
      immediate: true,
      handler() {
        this.paramsWatcher();
      },
    },
  },
  beforeDestroy() {
    if (this.button) {
      this.button.unmount();
      this.button = null;
    }
  },
};
</script>

<style scoped></style>
