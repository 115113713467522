<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-0" id="textField">
      <v-text-field
        @keydown.enter.prevent="$emit('onEnter', currentValue)"
        class="text-field"
        v-model="currentValue"
        :error-messages="error"
        :label="title"
        :placeholder="placeholder"
        data-cy="contact-info-text"
        outlined
        dense
        :hide-details="hideDetails"
        :rules="validationRules"
        :height="height ? height : 'auto'"
        aria-labelledby="textField"
        aria-selected="false"
        tabindex="0"
      />
    </v-col>
    <v-col cols="12" class="pa-0">
      <slot name="footer"></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormTextField',
  props: {
    title: String,
    placeholder: String,
    id: String,
    value: { type: String, default: '' },
    error: { type: String, default: '' },
    questionValidations: { type: Array, default: () => [] },
    hideDetails: Boolean,
    dense: Boolean,
    height: Number,
    isMasked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  computed: {
    validationRules() {
      let rules = [];
      if (this.questionValidations != null) {
        for (let validator of this.questionValidations) {
          if (
            validator.validationType == 'Required' &&
            validator.validationRule == 'True'
          ) {
            rules.push((v) => !!v || 'Required');
          } else if (validator.validationType == 'Regex') {
            let pattern = new RegExp(validator.validationRule);
            rules.push((v) => pattern.test(v) || validator.message);
          }
        }
      }
      return rules;
    },
    hasPrefilledCurrentValue() {
      return this.currentValue ? true : false;
    },
  },
  methods: {
    emitChangeEvent(newValue) {
      this.$emit('change', { value: newValue, id: this.id });
    },
  },
  created() {
    if (this.hasPrefilledCurrentValue) this.emitChangeEvent(this.currentValue);
  },
  updated() {
    this.isMasked
      ? this.emitChangeEvent(this.currentValue.replace(/-/g, ''))
      : this.emitChangeEvent(this.currentValue);
  },
};
</script>

<style lang="scss" scoped>
::v-deep.text-field {
  .v-input__slot {
    background: white;
  }

  .v-text-field__details {
    margin: 0;
    padding: 0;
  }
}
</style>
