export const getters = {
  hasCategories(state) {
    return (
      !!state &&
      !!state.categories &&
      !!state.categories.value &&
      !!state.categories.value.categories &&
      state.categories.value.categories.length > 0
    );
  },

  hasLoadedStore(state) {
    return (
      !!state &&
      !!state.categories &&
      !!state.categories.value &&
      Object.keys(state.categories.value).length > 0
    );
  },

  hasFlight(state) {
    return !!state && !!state.flight && !!state.flight.value;
  },

  hasFlightMisMatch(state) {
    return !!state && !!state.flightMismatch && !!state.flightMismatch.value;
  },

  categories(state) {
    if (!!state && !!state.categories && state.categories.value !== undefined) {
      return state.categories.value;
    }
    return null;
  },

  selectedCategory(state) {
    return !!state && !!state.selectedCategory && !!state.selectedCategory.name
      ? state.selectedCategory.name
      : null;
  },

  selectedOffering(state) {
    return !!state && !!state.selectedOffering && state.selectedOffering.value
      ? state.selectedOffering.value
      : null;
  },

  allCategoryOfferings(state) {
    return state.categoryListings.value;
  },

  hasListingsValue(state) {
    return (
      !!state &&
      !!state.categoryListings.value &&
      !!state.categoryListings.value.length
    );
  },

  isOpen(state) {
    if (
      getters.hasCategories(state) &&
      state.categories.value.status !== undefined
    ) {
      return state.categories.value.status.toLowerCase() === 'open';
    }
    return null;
  },
  isCatalogAvailable(state) {
    return state.isCatalogAvailable;
  },
  cartItems(state) {
    return !!state && !!state.cartItems && state.cartItems.value
      ? state.cartItems.value
      : null;
  },
  lastOrder(state) {
    return !!state && !!state.lastOrder && state.lastOrder.value
      ? state.lastOrder.value
      : null;
  },
  hasCartItems(state) {
    return (
      !!state &&
      !!state.cartItems &&
      !!state.cartItems.value &&
      state.cartItems.value.length > 0
    );
  },

  searchResults(state) {
    return !!state && !!state.searchResults && state.searchResults.value
      ? state.searchResults.value
      : null;
  },

  searchText(state) {
    return !!state && !!state.searchResults && state.searchResults.text
      ? state.searchResults.text
      : null;
  },

  hasSearchResults(state) {
    return (
      !!state &&
      !!state.searchResults &&
      !!state.searchResults.value &&
      state.searchResults.value.length > 0
    );
  },

  hasCreatedOrder: (state) =>
    !!state.orderCreation &&
    !!state.orderCreation.response &&
    state.orderCreation.error === null,

  hasCheckedOutOrder: (state) =>
    !!state.orderCheckout &&
    !!state.orderCheckout.response &&
    state.orderCheckout.error === null &&
    state.orderCheckout.clientConfirmationKey === null,

  stripeError: (state) =>
    !!state.checkoutForms && !!state.checkoutForms.stripePaymentError
      ? state.checkoutForms.stripePaymentError
      : null,

  hasLastOrder: (state) =>
    !!state &&
    !!state.lastOrder &&
    !!state.lastOrder.value &&
    state.lastOrder.value.length > 0,

  hasCategoriesError: (state) =>
    !!state.categories && Boolean(state.categories.error),

  categoriesError: (state) =>
    !!state.categories && !!state.categories.error
      ? state.categories.error
      : null,

  flight(state) {
    return getters.hasFlight(state) ? state.flight.value : null;
  },
  specialInstructions(state) {
    return !!state && !!state.instruction && !!state.instruction.value
      ? state.instruction.value
      : null;
  },
  hasInstructions: (state) =>
    !!state && !!state.instruction && !!state.instruction.value,

  highlightedOffering: (state) =>
    !!state && !!state.highlightedOffering && state.highlightedOffering.value
      ? state.highlightedOffering.value
      : null,

  isInfoFormValid(state) {
    return !!state && state.checkoutForms.isInfoFormValid;
  },

  isPaymentFormValid(state) {
    return !!state && state.checkoutForms.isPaymentFormValid;
  },
};
