export const state = () => ({
  isCatalogAvailable: true,
  categories: {
    error: null,
    isLoading: false,
    value: {},
  },

  flight: {
    error: null,
    isLoading: false,
    value: null,
  },

  categoryListings: {
    error: null,
    isLoading: false,
    value: [],
  },

  selectedCategory: {
    name: '',
  },

  selectedOffering: {
    value: null,
  },

  currentOrderItem: {
    value: null,
  },

  cartItems: {
    value: [],
  },

  orderCreation: {
    isCreating: false,
    response: null,
    error: null,
    status: 'Uninitialized',
  },

  orderCheckout: {
    response: null,
    clientConfirmationKey: null,
    isCheckingOut: false,
    error: null,
    status: '',
  },

  customerInfo: {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    seatRow: '',
    seatColumn: '',
    noSeat: false,
    pnr: '',
  },

  lastOrder: {
    value: [],
  },

  instruction: {
    value: '',
  },

  shopStatusError: {
    value: null,
  },

  checkoutForms: {
    isInfoFormValid: false,
    isPaymentFormValid: false,
    stripePaymentError: null,
  },

  highlightedOffering: {
    value: null,
  },

  searchResults: {
    text: null,
    value: [],
  },

  flightMismatch: {
    value: false,
  },
});
