<script>
import ShopStoreModule from '../store/index';

export default {
  name: 'ShopMixins',
  methods: {
    registerStore() {
      const store = this.$store;
      if (!ShopStoreModule[`registered-${this.storeModuleName}`]) {
        store.registerModule(this.storeModuleName, ShopStoreModule, {
          preserveState: !!store.state[this.storeModuleName],
        });
        ShopStoreModule[`registered-${this.storeModuleName}`] = true;
      }
    },

    loadCategories() {
      this.$store.dispatch(`${this.storeModuleName}/loadCategories`, {
        storeId: this.shopStoreId,
      });
    },

    updateFlightMisMatchStatus(payload) {
      this.$store.dispatch(
        `${this.storeModuleName}/updateFlightMisMatchStatus`,
        payload
      );
    },

    loadListings(ids) {
      this.$store.dispatch(`${this.storeModuleName}/loadListings`, {
        storeId: this.shopStoreId,
        listingIds: ids,
      });
    },

    selectCategory(category) {
      this.$store.dispatch(`${this.storeModuleName}/selectCategory`, category);
    },

    selectOffering(offering) {
      this.$store.dispatch(`${this.storeModuleName}/selectOffering`, offering);
    },

    clearSelectedOffering() {
      this.$store.dispatch(`${this.storeModuleName}/clearSelectedOffering`);
    },

    updateStoreStatus(id) {
      this.$store.dispatch(`${this.storeModuleName}/updateStoreStatus`, id);
    },
    highlightOffering(id) {
      this.$store.dispatch(`${this.storeModuleName}/highlightOffering`, id);
    },
    clearHighlightedOffering() {
      this.$store.dispatch(`${this.storeModuleName}/clearHighlightedOffering`);
    },
    clearSelectedCategory() {
      this.$store.dispatch(`${this.storeModuleName}/clearSelectedCategory`);
    },
  },

  computed: {
    allCategories() {
      if (this.hasCategories) {
        return this.categories.categories;
      } else return [];
    },
    flight() {
      return this.$store.getters[`${this.storeModuleName}/flight`];
    },

    shopStoreId() {
      return this.$route.params.id;
    },

    storeModuleName() {
      return `shop/${this.shopStoreId}`;
    },

    hasFlightMisMatch() {
      return this.$store.getters[`${this.storeModuleName}/hasFlightMisMatch`];
    },

    hasCategories() {
      return this.$store.getters[`${this.storeModuleName}/hasCategories`];
    },

    hasLoadedStore() {
      return this.$store.getters[`${this.storeModuleName}/hasLoadedStore`];
    },

    customerInfo() {
      return this.$store.state[`${this.storeModuleName}`].customerInfo;
    },

    hasFlight() {
      return this.$store.getters[`${this.storeModuleName}/hasFlight`];
    },

    hasSelectedOffering() {
      return (
        this.selectedOffering !== null && this.selectedOffering !== undefined
      );
    },

    selectedCategory() {
      return this.$store.getters[`${this.storeModuleName}/selectedCategory`];
    },

    selectedOffering() {
      return this.$store.getters[`${this.storeModuleName}/selectedOffering`];
    },

    categories() {
      return this.$store.getters[`${this.storeModuleName}/categories`];
    },

    isSelectedOfferingAvailable() {
      return this.selectedOffering.isAvailable;
    },

    tagGroups() {
      return this.categories.categories;
    },
    categoryOfferingTypeIds() {
      if (this.tagGroups && this.tagGroups.length) {
        const ids = this.tagGroups.map((tagGroup) => {
          return tagGroup.offeringTypes.map((x) => x.offeringTypeId);
        });
        return [].concat(...ids);
      }
      return [];
    },
    categorySlugs() {
      if (
        this.categories &&
        this.categories.categories &&
        this.categories.categories.length
      ) {
        return this.categories.categories.map((category) => category.slug);
      }
      return [];
    },

    isLoadingStore() {
      return this.$store.state[this.storeModuleName].categories.isLoading;
    },

    isLoadingCategoryListings() {
      return this.$store.state[this.storeModuleName].categoryListings.isLoading;
    },

    isOpen() {
      return this.$store.getters[`${this.storeModuleName}/isOpen`];
    },
    isCatalogAvailable() {
      return this.$store.getters[`${this.storeModuleName}/isCatalogAvailable`];
    },
    shopTitle() {
      return this.categories?.title ?? '';
    },
    shopSubtitle() {
      return this.categories?.subTitle ?? '';
    },
    highlightedOffering() {
      return this.$store.getters[`${this.storeModuleName}/highlightedOffering`];
    },
    hasCategoriesError() {
      return this.$store.getters[`${this.storeModuleName}/hasCategoriesError`];
    },
    categoriesError() {
      return this.$store.getters[`${this.storeModuleName}/categoriesError`];
    },
  },
};
</script>
