<template>
  <div>
    <svg
      class="bullet"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :class="{ secondary: isActive }"
        v-if="isActive"
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      />
      <path
        :class="{ secondary: isActiveOrDone, regular: !isActiveOrDone }"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17ZM19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ProgressIndicatorBullet',
  props: {
    status: {
      default: 'PENDING',
      type: String,
      validator: (val) => {
        return ['PENDING', 'DONE', 'ACTIVE'].indexOf(val) > -1;
      },
    },
  },
  computed: {
    isActive() {
      return this.status === 'ACTIVE';
    },
    isDone() {
      return this.status === 'DONE';
    },
    isActiveOrDone() {
      return this.isActive || this.isDone;
    },
  },
};
</script>

<style lang="scss" scoped>
.bullet {
  transform: scale(1.5);
  margin-left: -10px;
  .regular {
    fill: #857f72;
  }
  .secondary {
    fill: var(--v-secondary-base);
  }
}
</style>
