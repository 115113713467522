<template>
  <button @click="$emit('create-pay')" class="payment-type">
    <img
      class="payment-type__icon"
      :src="`/img/icons/${paymentIcon}.svg`"
      :alt="paymentName"
    />
  </button>
</template>

<script>
export default {
  name: 'PaymentTypeButton',
  props: {
    paymentIcon: String,
    paymentName: String,
  },
};
</script>
<style scoped lang="scss">
.payment-type {
  padding: 10px;
  background-color: black;
  height: 50px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  &:hover {
    background-color: #3c4043;
  }
  &__icon {
    height: 30px;
  }
}
</style>
