import { RepoFactory } from '@/core/repositories';
import * as mutationTypes from './mutation-types';
import * as mutations from '@/modules/product/store/mutation-types';
import { i18n } from '@/plugins/i18n';

export const actions = {
  async loadCategories({ commit }, { storeId }) {
    const storeRepo = RepoFactory.get('shop', { resourceId: storeId });
    commit(mutationTypes.CATEGORIES_LOADING);
    try {
      const categories = await storeRepo.getCategories();
      if (!!categories.storeDetails) {
        const { details, storeType } = categories.storeDetails;
        // Add other store type loads to switch block
        switch (storeType) {
          case 'FLIGHT':
            await actions.loadFlight({ commit }, { flightId: details.id });
            break;
        }
      }

      let isCatalogAvailable =
        !!categories &&
        !!categories.categories &&
        categories.categories.length > 0;

      commit(mutationTypes.CATALOG_AVAILABILITY_UPDATE, isCatalogAvailable);

      if (!isCatalogAvailable) {
        commit(mutationTypes.LISTINGS_CLEAR);
      }

      commit(mutationTypes.CATEGORIES_LOADED, categories);
    } catch (e) {
      let errorObject = {
        message: e.toString() || '',
        status: e && e.response ? e.response.status : null,
      };
      commit(mutationTypes.CATEGORIES_ERROR, errorObject);
    }
  },

  async loadListings({ commit }, { storeId, listingIds }) {
    const storeRepo = RepoFactory.get('shop', { resourceId: storeId });
    commit(mutationTypes.LISTINGS_LOADING);
    try {
      const data = await storeRepo.getTagGroups(listingIds);
      commit(mutationTypes.LISTINGS_LOADED, data.offerings);
    } catch (e) {
      commit(mutationTypes.LISTINGS_ERROR, e);
    }
  },

  async loadFlight({ commit }, { flightId }) {
    const flightRepo = RepoFactory.get('flight', { resourceId: flightId });
    commit(mutationTypes.FLIGHT_LOADING);
    try {
      const flightResponse = await flightRepo.getFlight();
      commit(mutationTypes.FLIGHT_LOADED, flightResponse.data);
    } catch (e) {
      commit(mutationTypes.FLIGHT_ERROR, e);
    }
  },

  async createOrder({ commit }, { amount, products }) {
    commit(mutationTypes.RESET_ORDER_CREATION);
    const orderService = RepoFactory.get('order');
    commit(mutationTypes.ORDER_CREATING);

    try {
      const response = await orderService.createOrder({ amount, products });
      commit(mutationTypes.ORDER_CREATED, { response: response.data });
    } catch (error) {
      const { response } = error;
      let message = i18n.t('shop.errors.something_wrong');
      let status = 500;
      if (response && !!response.data) {
        const { errorCode, errorMessage, details } = response.data;
        message = errorMessage ?? message;
        status = errorCode?.toString() ?? status;
        if (status === '2044') {
          message = i18n.t('shop.errors.store_closed');
        } else {
          if (details) {
            try {
              const parsedDetils = JSON.parse(details);
              status = parsedDetils.error;
              message =
                parsedDetils.error === 'storeUnavailable'
                  ? i18n.t('shop.errors.store_closed')
                  : parsedDetils.detail;
            } catch (e) {
              message = i18n.t('shop.errors.something_wrong');
            }
          }
        }
      } else {
        status = 'Error';
        message = error.message || error;
      }
      commit(mutationTypes.ORDER_CREATION_ERROR, { error: message, status });
    }
  },

  evaluateOrderStatus({ commit, dispatch }, { order, orderId }) {
    const { status } = order;
    const stopStatusConditions = ['Confirmed', 'Deferred'];
    if (status === 'Failed') {
      commit(mutationTypes.ORDER_CHECK_OUT_ERROR, {
        error: i18n.t('shop.errors.could_not_complete'),
        fatalError: true,
      });
    } else if (status === 'Declined') {
      commit(mutationTypes.ORDER_CHECK_OUT_ERROR, {
        error: i18n.t('shop.errors.could_not_confirm'),
      });
    } else if (stopStatusConditions.indexOf(status) > -1) {
      commit(mutationTypes.ORDER_CHECKED_OUT, {
        response: order,
      });
    } else {
      // update response, and schedule a new polling
      commit(mutationTypes.ORDER_CHECKED_OUT, {
        response: order,
      });
      dispatch('startCheckOutOrderPolling', { orderId });
    }
  },

  async startCheckOutOrderPolling({ commit, state, dispatch }, { orderId }) {
    const orderService = RepoFactory.get('order', {
      resourceId: orderId,
    });
    let timeoutId;
    const pollOrder = async () => {
      try {
        const { data } = await orderService.pollOrder();
        // check the order status should reschedule?
        if (!!data && !!data.order) {
          dispatch('evaluateOrderStatus', {
            order: data.order,
            orderId,
          });
        }
      } catch (error) {
        let errorMessage =
          !!error.response && !!error.response.data
            ? error.response.data.errorMessage
            : error.message;
        commit(mutationTypes.ORDER_CHECK_OUT_ERROR, {
          error: errorMessage,
        });
      }
    };
    if (!!state.orderCheckout) {
      const checkout = state.orderCheckout;
      if (checkout.maxpollRetry > 0 && checkout.pollingRequiredInSec > 0) {
        timeoutId = setTimeout(() => {
          pollOrder();
        }, checkout.pollingRequiredInSec * 1000);

        commit(mutationTypes.ORDER_CHECK_OUT_TIMEOUT_ID, {
          timeoutId: timeoutId,
        });
      } else if (checkout.maxpollRetry < 1) {
        commit(mutationTypes.ORDER_CHECK_OUT_ERROR, {
          error: i18n.t('shop.errors.could_not_confirm'),
        });
      }
    }
  },

  async checkOutOrder(
    { commit, state },
    {
      orderId,
      paymentMethodType,
      paymentMethodId,
      sourceId,
      discountToken,
      asyncCall,
    }
  ) {
    commit(mutationTypes.RESET_ORDER_CHECKOUT);
    const orderService = RepoFactory.get('order', { resourceId: orderId });
    commit(mutationTypes.ORDER_CHECKING_OUT);
    try {
      const response = await orderService.checkOutOrder({
        paymentMethodType,
        discountToken,
        sourceId,
        paymentMethodId,
        asyncCall,
      });
      if (asyncCall && response.order) {
        commit(mutationTypes.ORDER_CHECKED_OUT_ASYNC, {
          response: response.order.order,
          pollingRequiredInSec: response.order.pollingRequiredInSec,
        });
      } else if (response.order && response.clientConfirmationKey === null) {
        commit(mutationTypes.ORDER_CHECKED_OUT, { response: response.order });
      } else {
        commit(mutationTypes.ORDER_CHECK_OUT_REQUIRES_CONFIRMATION, {
          clientConfirmationKey: response.clientConfirmationKey,
        });
      }
    } catch (error) {
      const { response } = error;
      let message = i18n.t('shop.errors.something_wrong');
      let status = 500;
      if (response && !!response.data) {
        const { errorCode, errorMessage } = response.data;
        message = errorMessage ?? message;
        status = errorCode?.toString() ?? status;
        if (status === '2044') {
          message = i18n.t('shop.errors.store_closed');
        } else if (status === '2045') {
          message = i18n.t('shop.errors.items_out_of_stock');
        }
      } else {
        status = 'Error';
        message = error.message || error;
      }
      commit(mutationTypes.ORDER_CHECK_OUT_ERROR, { error: message, status });
    } finally {
      commit(mutations.ORDER_CHECKING_OUT, {
        isCheckingOut: false,
      });
    }
  },
  selectCategory({ commit }, category) {
    commit(mutationTypes.CATEGORY_SELECTED, category);
  },

  modifyStoreStatus({ commit }, storeModified) {
    commit(mutationTypes.SHOP_STATUS_MODIFIED, storeModified.status);
  },

  selectOffering({ commit }, category) {
    commit(mutationTypes.OFFERING_SELECTED, category);
  },

  clearSelectedOffering({ commit }) {
    commit(mutationTypes.CLEAR_SELECTED_OFFERING);
  },

  setCurrentOrderItem({ commit }, currentOrderItem) {
    commit(mutationTypes.CURRENT_ORDER_ITEM, currentOrderItem);
  },

  clearOrderItem({ commit }) {
    commit(mutationTypes.CLEAR_ORDER_ITEM);
  },

  addItemToCart({ commit }, item) {
    commit(mutationTypes.ADD_ITEM_TO_CART, item);
  },

  addSearchItemResults({ commit }, { item, text }) {
    commit(mutationTypes.SHOP_SEARCH_RESULT, { item, text });
  },
  clearSearchResults({ commit }) {
    commit(mutationTypes.CLEAR_SHOP_SEARCH_RESULT);
  },

  updateItemInCart({ commit }, { item, orderId }) {
    return commit(mutationTypes.UPDATE_ITEM_IN_CART, { item, orderId });
  },

  removeItemFromCart({ commit }, id) {
    commit(mutationTypes.REMOVE_ITEM_FROM_CART, id);
  },

  updateCustomerInfo({ commit }, customer) {
    commit(mutationTypes.UPDATE_CUSTOMER_INFO, customer);
  },

  resetRestrictionAcknowledged({ commit }) {
    commit(mutationTypes.RESET_RESTRICTION_ACKNOWLEDGEMENT);
  },

  clearCart({ commit }) {
    commit(mutationTypes.CLEAR_CART);
  },

  updateFlightMisMatchStatus({ commit }, payload) {
    commit(mutationTypes.FLIGHT_MISMATCH, payload);
  },

  updateLastOrder({ commit }, order) {
    commit(mutationTypes.UPDATE_LAST_ORDER, order);
  },

  resetOrderCreation({ commit }) {
    commit(mutationTypes.RESET_ORDER_CREATION);
  },

  resetOrderCheckout({ commit }) {
    commit(mutationTypes.RESET_ORDER_CHECKOUT);
  },

  clearOrderCreationError({ commit }) {
    commit(mutationTypes.CLEAR_ORDER_CREATION_ERRORS);
  },

  clearOrderCheckoutError({ commit }) {
    commit(mutationTypes.CLEAR_ORDER_CHECKOUT_ERRORS);
  },

  clearStripeError({ commit }) {
    commit(mutationTypes.CLEAR_STRIPE_ERROR);
  },

  updateStripeError({ commit }, error) {
    commit(mutationTypes.UPDATE_STRIPE_ERROR, error);
  },

  updateSpecialInstructions({ commit }, instruction) {
    commit(mutationTypes.UPDATE_SPECIAL_INSTRUCTIONS, instruction);
  },

  clearSpecialInstructions({ commit }) {
    commit(mutationTypes.CLEAR_SPECIAL_INSTRUCTIONS);
  },

  async updateStoreStatus({ commit }, id) {
    const storeRepo = RepoFactory.get('shop', { resourceId: id });
    try {
      const status = await storeRepo.getStoreStatus();
      commit(mutationTypes.SHOP_STATUS_MODIFIED, status);
    } catch (e) {
      commit(mutationTypes.SHOP_STATUS_ERROR, e.response.data);
    }
  },

  updateIsInfoFormValid({ commit }, value) {
    commit(mutationTypes.UPDATE_IS_INFO_FORM_VALID, value);
  },

  updateIsPaymentFormValid({ commit }, value) {
    commit(mutationTypes.UPDATE_IS_PAYMENT_FORM_VALID, value);
  },

  resetInfoFormValidations({ commit }) {
    commit(mutationTypes.RESET_INFO_FORM_VALIDATIONS);
  },

  resetPaymentFormValidations({ commit }) {
    commit(mutationTypes.RESET_PAYMENT_FORM_VALIDATIONS);
  },

  highlightOffering({ commit }, id) {
    commit(mutationTypes.HIGHLIGHTED_OFFERING, id);
  },
  clearHighlightedOffering({ commit }) {
    commit(mutationTypes.CLEAR_HIGHLIGHTED_OFFERING);
  },
  clearSelectedCategory({ commit }) {
    commit(mutationTypes.CLEAR_SELECTED_CATEGORY);
  },
  updateDiscount({ commit }, { response }) {
    commit(mutations.ORDER_UPDATE_DISCOUNT, { response });
  },
  removeDiscount({ commit }) {
    commit(mutations.ORDER_REMOVE_DISCOUNT);
  },
};
