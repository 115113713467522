<script>
export default {
  name: 'PaymentMixins',
  methods: {
    loadSavedPaymentMethods(travelerId) {
      this.$store.dispatch('payment/loadSavedPaymentMethods', { travelerId });
    },
  },
  computed: {
    hasSavedCards() {
      return this.$store.getters['payment/hasSavedCards'];
    },
    savedCards() {
      return this.$store.getters['payment/savedCards'];
    },
  },
};
</script>
