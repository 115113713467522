<script>
import ShopMixins from './ShopMixins.vue';
import ConfigsMixins from '@/core/mixins/ConfigsMixins.vue';
export default {
  name: 'ShopOrderMixin',
  mixins: [ShopMixins, ConfigsMixins],
  data() {
    return {
      useDiscounts: false,
      snackbar: false,
    };
  },
  created() {
    this.checkDiscountFF();
  },
  methods: {
    closeSnackBar() {
      this.snackbar = false;
    },
    updateInfoFormValid(isValid) {
      this.$store.dispatch(
        `${this.storeModuleName}/updateIsInfoFormValid`,
        isValid
      );
    },

    updatePaymentFormValid(isValid) {
      this.$store.dispatch(
        `${this.storeModuleName}/updateIsPaymentFormValid`,
        isValid
      );
    },

    updateCustomerInfo(customer) {
      this.$store.dispatch(
        `${this.storeModuleName}/updateCustomerInfo`,
        customer
      );
    },

    resetRestrictionAcknowledged() {
      this.$store.dispatch(
        `${this.storeModuleName}/resetRestrictionAcknowledged`
      );
    },

    setCurrentOrderItem(item) {
      this.$store.dispatch(`${this.storeModuleName}/setCurrentOrderItem`, item);
    },
    clearOrderItem() {
      this.$store.dispatch(`${this.storeModuleName}/clearOrderItem`);
    },
    addItemToCart(item) {
      this.$store.dispatch(`shopCart/addItemToCart`, {
        item,
        shopId: this.shopStoreId,
      });
    },
    updateItemInCart(orderId, item) {
      return this.$store.dispatch(`shopCart/updateItemInCart`, {
        item,
        orderId,
        shopId: this.shopStoreId,
      });
    },
    removeItemFromCart(id) {
      this.$store.dispatch(`shopCart/removeItemFromCart`, {
        orderId: id,
        shopId: this.shopStoreId,
      });
    },
    updateLastOrder(order) {
      this.$store.dispatch(`${this.storeModuleName}/updateLastOrder`, order);
    },
    clearOrderCreationError() {
      this.$store.dispatch(`${this.storeModuleName}/clearOrderCreationError`);
    },
    clearOrderCheckoutError() {
      this.$store.dispatch(`${this.storeModuleName}/clearOrderCheckoutError`);
    },
    clearStripeError() {
      this.$store.dispatch(`${this.storeModuleName}/clearStripeError`);
    },
    updateStripeError(error) {
      this.$store.dispatch(`${this.storeModuleName}/updateStripeError`, error);
    },
    resetInfoFormValidations() {
      return this.$store.dispatch(
        `${this.storeModuleName}/resetInfoFormValidations`
      );
    },
    resetPaymentFormValidations() {
      return this.$store.dispatch(
        `${this.storeModuleName}/resetPaymentFormValidations`
      );
    },
    async createOrder() {
      const customerInfo = this.customerInfo;
      const amount = {
        value: 0,
        currency: this.defaultCurrency,
      };
      const products = [];
      this.cartItems.forEach((item, i) => {
        for (let i = 0; i < item.quantity; i++) {
          const hasSeat =
            Boolean(this.customerInfo.seatRow) &&
            Boolean(this.customerInfo.seatColumn);
          let product = {
            id: item.offering.id,
            passes: item.passes,
            answers: [
              {
                questionId: 'que_Tf+qgPKy$h$C*qHK$_', // title
                value: this.customerInfo.title,
              },
              {
                questionId: 'que_Tf+qgPKy$h$2*y+qNAPK$_', // first name
                value: this.customerInfo.firstName,
              },
              {
                questionId: 'que_Tf+qgPKy$h$RA+qNAPK$_', // last name
                value: this.customerInfo.lastName,
              },
              {
                questionId: 'que_Tf+qgPKy$h$IPA*H$_', // email
                value: this.customerInfo.email,
              },
              {
                questionId: 'que_2H*o0q$h$UKAqLgc$_', // seat row
                value: this.customerInfo.seatRow,
              },
              {
                questionId: 'que_2H*o0q$h$UKAqTgHfP8$_', // seat column
                value: this.customerInfo.seatColumn,
              },
              {
                questionId: 'que_2H*o0q$h$NgUKAq$_', // no seat
                value: this.customerInfo.noSeat && !hasSeat,
              },
            ],
          };
          if (this.customerInfo.specialInstructions) {
            product.answers = [
              ...product.answers,
              {
                questionId: 'que_xyJKy$h$U!Kw*AHE8+qyfwq*g8+$_', // special instructions
                value: this.customerInfo.specialInstructions,
              },
            ];
          }
          if (this.customerInfo.pnr) {
            product.answers = [
              ...product.answers,
              {
                questionId: 'que_2H*o0q$h$mA++K8oKyNAPKLKwgyJ$_', // pnr
                value: this.customerInfo.pnr,
              },
            ];
          }
          if (this.hasCartAnyRestrictedItems) {
            product.answers = [
              ...product.answers,
              {
                questionId: 'que_xyJKy$h$E+aw38gcHKJoKJ$_', // restriction Acknowledgment
                value: this.customerInfo.restrictionAcknowledged,
              },
            ];
          }
          products.push(product);
        }
      });
      await this.dispatchCreateOrder({
        amount,
        products,
      });
    },
    dispatchCreateOrder(order) {
      return this.$store.dispatch(`${this.storeModuleName}/createOrder`, order);
    },

    checkOutOrder({
      orderId,
      paymentMethodType,
      paymentMethodId,
      sourceId,
      discountToken,
      asyncCall,
    }) {
      return this.$store.dispatch(`${this.storeModuleName}/checkOutOrder`, {
        orderId,
        paymentMethodType,
        paymentMethodId,
        sourceId,
        discountToken,
        asyncCall,
      });
    },

    clearCart() {
      this.$store.dispatch(`shopCart/clearCart`, { shopId: this.shopStoreId });
    },

    clearOrderState() {
      this.resetOrderCreation();
      this.resetOrderCheckout();
    },

    clearOrderCreationState() {
      this.$store.dispatch(`${this.storeModuleName}/clearOrderCreation`);
    },

    resetOrderCreation() {
      this.$store.dispatch(`${this.storeModuleName}/resetOrderCreation`);
    },

    resetOrderCheckout() {
      this.$store.dispatch(`${this.storeModuleName}/resetOrderCheckout`);
    },

    updateSpecialInstructions(instructions) {
      this.$store.dispatch(
        `${this.storeModuleName}/updateSpecialInstructions`,
        instructions
      );
    },

    clearSpecialInstructions() {
      this.$store.dispatch(`${this.storeModuleName}/clearSpecialInstructions`);
    },
    async checkDiscountFF() {
      this.useDiscounts = await this.$ldclient.variation(
        'discount',
        false,
        true
      );
      this.$ldclient.$on('change:discount', (value) => {
        this.useDiscounts = value;
      });
    },
    startCheckoutPolling(
      { order, orderId } = {
        order: null,
        orderId,
      }
    ) {
      return this.$store.dispatch(
        `${this.storeModuleName}/startCheckOutOrderPolling`,
        {
          order,
          orderId,
        }
      );
    },
    removeDiscount() {
      return this.$store.dispatch(`${this.storeModuleName}/removeDiscount`);
    },
    updateDiscount(response) {
      return this.$store.dispatch(`${this.storeModuleName}/updateDiscount`, {
        response,
      });
    },
  },
  computed: {
    discount() {
      if (!this.hasCreatedOrder || !this.createdOrder.discount) return null;
      return {
        label: this.createdOrder.discount.label,
        name: this.createdOrder.discount.promoCode,
        discountAmount: this.createdOrder.amount.discountTotal,
        finalTotal: this.createdOrder.amount.value,
        termsAndConditions: this.createdOrder.discount.termsAndConditions,
      };
    },
    hasCartItems() {
      return this.cartItems.length > 0;
    },
    cartItems() {
      return this.$store.state.shopCart.shopCarts[this.shopStoreId] || [];
    },
    hasCartAnyRestrictedItems() {
      return (
        this.cartItems.filter((item) => item.offering.isRestricted).length > 0
      );
    },
    totalCost() {
      return this.cartItems.reduce((acc, o) => acc + o.price.value, 0);
    },
    totalQuantity() {
      return this.cartItems.reduce((acc, o) => acc + o.quantity, 0);
    },
    orderRequest() {
      return !!this.$store.state[this.storeModuleName]
        ? this.$store.state[this.storeModuleName].orderRequest
        : null;
    },
    orderCreation() {
      return this.$store.state[this.storeModuleName].orderCreation;
    },
    orderCheckout() {
      return this.$store.state[this.storeModuleName].orderCheckout;
    },
    hasLastOrder() {
      return this.$store.getters[`${this.storeModuleName}/hasLastOrder`];
    },
    lastCreatedOrder() {
      return this.$store.getters[`${this.storeModuleName}/lastOrder`];
    },
    isCreatingOrder() {
      return this.orderCreation.isCreating;
    },

    hasOrderCreationError() {
      return (
        this.orderCreation.error !== undefined &&
        this.orderCreation.error !== null
      );
    },

    isAsyncCheckout() {
      return (
        !!this.orderCheckout &&
        !!this.orderCheckout.response &&
        !!this.orderCheckout.async
      );
    },
    orderCreationStatus() {
      return this.orderCreation.status;
    },

    orderTotalValue() {
      return this.createdOrderAmount
        ? this.createdOrderAmount.value
        : this.totalCost;
    },

    orderSubTotal() {
      return this.createdOrderAmount
        ? this.createdOrderAmount.subTotal
        : this.totalCost;
    },

    createdOrderAmount() {
      return this.createdOrder?.amount;
    },

    hasCreatedOrder() {
      return this.$store.getters[`${this.storeModuleName}/hasCreatedOrder`];
    },

    createdOrder() {
      if (!this.hasCreatedOrder) return null;
      return this.orderCreation.response;
    },

    createdOrderId() {
      if (!this.hasCreatedOrder) return null;
      return this.createdOrder.id;
    },

    createdDiscountToken() {
      if (!!this.createdOrder && !!this.createdOrder.discount)
        return this.createdOrder.discount.discountToken;
      else return null;
    },

    hasOrderCheckoutError() {
      return (
        this.orderCheckout.error !== undefined &&
        this.orderCheckout.error !== null
      );
    },

    hasCheckedOutOrder() {
      return this.$store.getters[`${this.storeModuleName}/hasCheckedOutOrder`];
    },

    checkoutStatus() {
      return !!this.hasCheckedOutOrder
        ? this.orderCheckout.response.status
        : null;
    },

    checkedOutOrder() {
      if (!this.hasCheckedOutOrder) return null;
      return this.orderCheckout.response;
    },

    isCheckingOutOrder() {
      return this.orderCheckout.isCheckingOut;
    },

    checkOutConfirmationKey() {
      return this.orderCheckout.clientConfirmationKey;
    },

    specialInstructions() {
      return this.$store.getters[`${this.storeModuleName}/specialInstructions`];
    },

    hasInstructions() {
      return this.$store.getters[`${this.storeModuleName}/hasInstructions`];
    },

    isInfoFormValid() {
      return this.$store.getters[`${this.storeModuleName}/isInfoFormValid`];
    },

    isPaymentFormValid() {
      return this.$store.getters[`${this.storeModuleName}/isPaymentFormValid`];
    },

    stripeError() {
      return this.$store.getters[`${this.storeModuleName}/stripeError`];
    },

    isPartnerOfferingsStoreClosed() {
      return this.orderCreationStatus === '2044';
    },

    isItemOutOfStock() {
      return this.orderCheckout.status === '2045';
    },

    isStoreUnavailableStatus() {
      return this.orderCreationStatus === 'storeUnavailable';
    },
  },
};
</script>
