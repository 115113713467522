<template>
  <v-container class="payment-list px-0">
    <v-row no-gutters>
      <v-col
        v-for="(card, j) in savedCreditCards"
        cols="12"
        :key="`saved-cc-${j}`"
        class="method-item mb-1"
        @change="onSavedCreditCardsSelection(card)"
      >
        <v-row align="center" no-gutters>
          <v-col class="pa-1 mr-4" cols="1">
            <CreditCardBrandIcon :creditCardBrand="card['brand']" />
          </v-col>
          <v-col cols="auto" class="size16-weight400">
            {{
              $t('product.credit_card_ending_with', {
                digits: card['last4Digits'],
              })
            }}
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            <ProgressIndicatorBullet :status="open ? 'ACTIVE' : 'PENDING'" />
          </v-col>
        </v-row>
        <v-divider :key="`saved-cc-divider-${j}`" />
      </v-col>
    </v-row>

    <v-row no-gutters class="payment-methods" justify="space-between">
      <v-col
        cols="12"
        :key="i"
        @click="selectedMethod = method"
        :class="[
          'paymentmethod-container pa-0',
          !availableMethods[method].visible && 'paymentmethod-container--hide',
        ]"
        v-for="(method, i) in methods"
      >
        <StripePaymentRequest
          :ref="`stripe${method}`"
          :method="method"
          @show-button="showButton($event, method)"
          @change="payInputChanged($event, method)"
          :price="amount.value"
          :currency="amount.baseCurrency"
          :label="method"
        />
      </v-col>
      <div
        :class="[
          'paymentmethod-container pa-0',
          !showAliButton && 'paymentmethod-container--hide',
        ]"
      >
        <template v-if="methods.includes('AliPay')">
          <PaymentTypeButton
            @create-pay="createAliPayPayment"
            paymentIcon="ali_payment"
            paymentName="AliPay"
          />
        </template>
      </div>
    </v-row>

    <template v-if="showDivider">
      <v-row>
        <v-col>
          <v-divider />
        </v-col>
        <span class="divider-text"> {{ $t('or') }}</span>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
    </template>

    <v-row class="creditcard-container pa-0 pb-3">
      <v-col cols="12" class="py-0">
        <h3 class="size14-weight600 my-2">
          {{ $t('product.credit_card') }}
        </h3>
        <StripeCard ref="stripeCard" @change="cardInputChanged"></StripeCard>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="auto" class="d-flex align-end">
        <simple-svg
          width="13"
          height="16"
          :src="require(`@/assets/lock.svg`)"
          custom-class-name="mr-3 mb-1"
        />
        <span class="size14-weight600 grey--text text--darken-1 mr-1">{{
          $t('product.secure_checkout_powered_by')
        }}</span>
        <simple-svg
          width="42"
          height="18"
          :src="require(`@/assets/stripe.svg`)"
          custom-class-name="mr-8"
        />
        <simple-svg :src="require(`@/assets/stripe_powered.svg`)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProgressIndicatorBullet from '@/modules/product/features/ProgressIndicator/ProgressIndicatorBullet';
import StripeCard from './StripeCard.vue';
import CreditCardBrandIcon from '@/modules/product/features/Payment/CreditCardBrandIcon';
import PaymentTypeButton from '@/modules/product/features/Payment/PaymentTypeButton';
import StripePaymentRequest from '@/modules/product/features/Payment/StripePaymentRequest';
import ProductOrderInformationMixins from '@/modules/product/booking/mixins/ProductOrderInformationMixins';

export default {
  name: 'PaymentsList',
  mixins: [ProductOrderInformationMixins],
  components: {
    StripePaymentRequest,
    CreditCardBrandIcon,
    PaymentTypeButton,
    ProgressIndicatorBullet,
    StripeCard,
  },

  props: {
    savedCreditCards: Array,
    methods: {
      type: Array,
      default: () => [],
    },
    amount: {
      type: Object,
      default: () => ({ value: 0, currency: 'USD' }),
    },
  },

  data() {
    return {
      selectedMethod: null,
      selectedSavedCreditCard: null,
      savePaymentMethodId: false,
      availableMethods: {
        CreditCard: {
          label: this.$t('product.add_credit_card'),
          icon: '',
          desc: '',
          completed: false,
          visible: false,
        },
        PayPal: {
          label: 'PayPal',
          icon: '',
          desc: '',
          completed: false,
          visible: false,
        },
        AliPay: {
          label: 'AliPay',
          icon: '/img/icons/ic-alipay.png',
          desc: this.$t('product.you_will_be_redirected_to_complete'),
          completed: false,
          requiresSource: true,
          source: null,
          visible: false,
        },
        WeChatPay: {
          label: 'WeChat Pay',
          icon: '/img/icons/ic-wechat.png',
          desc: '',
          completed: false,
          visible: false,
        },
        ApplePay: {
          label: this.$t('product.apple_pay'),
          icon: '/img/icons/ic-applepay.png',
          desc: '',
          completed: false,
          visible: false,
        },
        GooglePay: {
          label: this.$t('product.google_pay'),
          icon: '/img/icons/ic-googlepay.png',
          desc: '',
          completed: false,
          visible: false,
        },
      },
      showAliButton: false,
    };
  },

  methods: {
    showButton(isAvaliable, method) {
      if (isAvaliable && method) {
        const methodName = method.charAt(0).toLowerCase() + method.slice(1);
        this.availableMethods[method].visible = !!isAvaliable[methodName];
      }
      // just to fix layout bouncing
      setTimeout(
        () => (this.showAliButton = this.methods.includes('AliPay')),
        600
      );
    },

    createAliPayPayment() {
      this.selectedMethod = 'AliPay';
      this.$emit('create-alipayment');
    },

    cardInputChanged(eventArgs) {
      this.availableMethods['CreditCard'].completed = eventArgs.complete;
      this.selectedMethod = 'CreditCard';
      if (eventArgs.error) {
        this.availableMethods['CreditCard'].error = eventArgs.error.message;
      } else {
        this.availableMethods['CreditCard'].error = null;
      }
      this.$emit('change', 'CreditCard', {
        card: this.addedCard,
        completed: eventArgs.complete,
        savePaymentMethodId: this.savePaymentMethodId,
      });
    },

    payInputChanged(eventArgs, methodName) {
      this.$emit('change', methodName, eventArgs.paymentMethod);
    },

    onSavedCreditCardsSelection(card) {
      this.activeSavedCreditCard = card;
    },

    async createSource(
      type,
      { amount, currency, redirectUrl = window.location.href }
    ) {
      this.$emit('clear-error');
      this.$emit('loading', true);
      let source = null;
      try {
        source = await this.$stripe.lib.createSource({
          type: type,
          amount: amount,
          currency: currency,
          redirect: {
            return_url: redirectUrl,
          },
        });
      } catch (error) {
        this.$emit('error', error.message);
      } finally {
        this.$emit('loading', false);
        return source;
      }
    },

    async loadSource(method) {
      const { source, error } = await this.createSource(method.toLowerCase(), {
        amount: this.zeroDecimalIntegerAmount,
        currency: this.amount.baseCurrency,
      });

      if (error) {
        this.$emit('error', error);
      } else if (source) {
        this.availableMethods[method].source = source;
        this.availableMethods[method].completed = true;
        this.emitChangeEvent(method, this.availableMethods[method]);
      }
    },

    emitChangeEvent(method, options) {
      const extras = {};
      if (method === 'CreditCard') {
        extras.card = this.addedCard;
        extras.savePaymentMethodId = this.savePaymentMethodId;
      } else {
        extras.savePaymentMethodId = false;
      }
      if (options.requiresSource) {
        if (options.source == null) this.loadSource(method);
        extras.source = options.source;
      }

      extras.completed = this.availableMethods[method].completed;
      this.$emit('change', method, extras);
    },
  },

  computed: {
    addedCard() {
      return this.$refs.stripeCard.card;
    },

    activeSavedCreditCard: {
      get() {
        return this.selectedSavedCreditCard;
      },
      set(value) {
        if (value === this.selectedSavedCreditCard) {
          this.selectedSavedCreditCard = null;
        } else {
          if (value != null && this.activeMethod != null) {
            this.activeMethod = null;
          }
          this.selectedSavedCreditCard = value;
        }
      },
    },

    activeMethod: {
      get() {
        return this.selectedMethod;
      },
      set(value) {
        if (value === this.selectedMethod) {
          this.selectedMethod = null;
        } else {
          if (value != null && this.activeSavedCreditCard != null) {
            this.activeSavedCreditCard = null;
          }
          this.selectedMethod = value;
        }
      },
    },

    zeroDecimalIntegerAmount() {
      return Math.floor(this.amount.value * 100);
    },

    showDivider() {
      return (
        this.availableMethods.ApplePay.visible ||
        this.availableMethods.GooglePay.visible ||
        this.showAliButton
      );
    },
  },

  watch: {
    selectedMethod(method) {
      if (method === null) {
        this.$emit('change', null, null);
      } else {
        const options = this.availableMethods[method];
        this.emitChangeEvent(method, options);
      }
    },

    selectedSavedCreditCard(card) {
      this.$emit('changeSavedCreditCardsSelection', card);
    },

    savePaymentMethodId() {
      const options = this.availableMethods[this.selectedMethod];
      this.emitChangeEvent(this.selectedMethod, options);
    },
  },
};
</script>

<style scoped lang="scss">
.payment-list {
  .method-item {
    border-bottom: #cfd5e0 1px solid;
  }
  .payment-methods {
    display: flex;
    flex-wrap: nowrap;
    @media (max-width: map-get($grid-breakpoints, sm)) {
      flex-direction: column;
    }
  }
  .paymentmethod-container {
    box-sizing: border-box;
    flex: 1;
    max-width: 50%;

    &:not(:last-child) {
      margin-right: 12px;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        margin-right: 0;
      }
    }

    &--hide {
      display: none;
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
      max-width: 100%;
    }
  }

  .creditcard-container {
    box-sizing: border-box;
  }

  .divider-text {
    color: #9d9d9d;
    text-transform: uppercase;
  }
}
</style>
