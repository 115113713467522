<template>
  <v-img
    alt="credit card icon"
    contain
    :src="creditCardIcon"
    :max-height="30"
  />
</template>

<script>
export default {
  name: 'CreditCardBrandIcon',
  props: {
    creditCardBrand: String,
  },
  computed: {
    creditCardIcon() {
      switch (this.creditCardBrand) {
        case 'visa':
          return '/img/icons/ic-visa.png';
        case 'mastercard':
          return '/img/icons/ic-mastercard.png';
        case 'jcb':
          return '/img/icons/ic-jcb.png';
        case 'amex':
          return '/img/icons/ic-amex.png';
        default:
          return '/img/icons/ic-card.png';
      }
    },
  },
};
</script>
