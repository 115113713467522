<template>
  <div id="card-element" ref="stripeCard" class="stripe-card py-2"></div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';

export default {
  name: 'StripeCard',
  mixins: [ShopOrderMixins],
  data() {
    return {
      card: null,
      stripeNotLoaded: null,
    };
  },
  mounted() {
    if (!!this.$stripe && this.$stripe.lib) {
      this.stripeNotLoaded = false;
      this.setupStripe();
    } else {
      this.stripeNotLoaded = true;
      this.$store.dispatch('initStripe', {
        stripe_key: this.stripeKey,
        vm: this,
      });
      Sentry.captureException(
        new Error('Attempt to mount StripeCard; Stripe library is undefined')
      );
    }
  },
  computed: {
    stripeKey() {
      return this.$store.state.configs.stripe_key;
    },
  },
  methods: {
    async setupStripe() {
      const style = {
        invalid: {
          color: this.$vuetify.theme.themes.light.error,
          iconColor: this.$vuetify.theme.themes.light.error,
        },
      };
      this.card = this.$stripe.lib.elements().create('card', { style: style });
      this.card.mount('#card-element');
      this.card.addEventListener('change', (event) =>
        this.$emit('change', event)
      );

      this.card.addEventListener('ready', (event) =>
        this.$refs.stripeCard
          .querySelector('.__PrivateStripeElement-input')
          .focus()
      );
    },
  },
  watch: {
    ['$stripe.lib']: {
      immediate: true,
      deep: true,
      handler(val, oldval) {
        if (!!val && !!this.stripeNotLoaded) {
          this.setupStripe();
        }
      },
    },
  },
  beforeDestroy() {
    if (this.card) {
      this.card.unmount();
      this.card = null;
    }
  },
};
</script>

<style scoped>
.stripe-card {
  border: 2px solid #e4e8ef;
  border-radius: 8px;
  padding: 10px 12px;
  background: #ffffff;
  width: 100%;
}
</style>
